// initial state
const state = {
    timeType: null,
};

// getters
const getters = {
    getTimeType(state, getters) {
        return state.timeType;
    },
};

// mutations
const mutations = {
    setTimeType(state, data) {
        state.timeType = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
