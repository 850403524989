import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/initial/Home.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomeDemo',
        component: () => import('@/views/initial/HomeDemo.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/initial/Login.vue'),
        children: [{ path: 'incorrectdata', name: 'IncorretData', component: () => import('@/components/notifications/IncorrectData.vue') }],
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/initial/Register.vue'),
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        component: () => import('@/views/initial/ForgotPassword.vue'),
    },
    {
        path: '/reset-password',
        name: 'NewPassword',
        component: () => import('@/views/initial/NewPassword.vue'),
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/Profile.vue'),
    },
    {
        path: '/personaldata',
        name: 'Personal Data',
        component: () => import('@/views/profile/PersonalData.vue'),
    },
    {
        path: '/favorite',
        name: 'Favorite',
        component: () => import('@/views/profile/FavoriteView.vue'),
    },
    {
        path: '/help',
        name: 'Help',
        component: () => import('@/views/profile/Help.vue'),
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/profile/settings/Settings.vue'),
    },
    {
        path: '/language',
        name: 'Language',
        component: () => import('@/views/profile/settings/Language.vue'),
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: () => import('@/views/profile/settings/Notifications.vue'),
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/profile/settings/privacy/Privacy.vue'),
    },
    {
        path: '/contact',
        name: 'Contacts',
        component: () => import('@/views/profile/settings/Contacts.vue'),
    },
    {
        path: '/deletedata',
        name: 'DeleteData',
        component: () => import('@/views/profile/settings/privacy/DeleteData.vue'),
    },
    {
        path: '/accountinactivation',
        name: 'AccountInactivation',
        component: () => import('@/views/profile/settings/privacy/AccountInactivation.vue'),
    },
    {
        path: '/dataportability',
        name: 'DataPortability',
        component: () => import('@/views/profile/settings/privacy/DataPortability.vue'),
    },
    {
        path: '/removeconsent',
        name: 'RemoveConsent',
        component: () => import('@/views/profile/settings/privacy/RemoveConsent.vue'),
    },
    {
        path: '/train',
        name: 'TrainSchedule',
        component: () => import('@/views/routes/TrainSchedule.vue'),
    },
    {
        path: '/bus',
        name: 'BusSchedule',
        component: () => import('@/views/routes/BusSchedule.vue'),
    },
    {
        path: '/route',
        name: 'SingleRoute',
        component: () => import('@/views/routes/SingleRoute.vue'),
    },
    {
        path: '/parkmap',
        name: 'ParkMap',
        component: () => import('@/views/parks/ParkMap.vue'),
    },
    {
        path: '/parkreservation',
        name: 'ParkReservation',
        component: () => import('@/views/parks/ParkReservation.vue'),
    },
    {
        path: '/transitdetails/:id',
        name: 'TransitRouteDetails',
        component: () => import('@/components/routes/TransitRouteDetails.vue'),
    },
    {
        path: '/main',
        name: 'BottomMenu',
        component: () => import('@/views/BottomMenu.vue'),

        children: [
            {
                path: 'research',
                name: 'Research',
                component: () => import('@/components/search/Research.vue'),
            },
            {
                path: 'profile',
                name: 'ProfileDesktop',
                component: () => import('@/views/profile/Profile.vue'),
            },
            {
                path: 'personaldata',
                name: 'PersonalDataDesktop',
                component: () => import('@/views/profile/PersonalData.vue'),
            },
            {
                path: 'favorite',
                name: 'FavoriteDesktop',
                component: () => import('@/views/profile/FavoriteView.vue'),
            },
            {
                path: 'help',
                name: 'HelpDesktop',
                component: () => import('@/views/profile/Help.vue'),
            },
            {
                path: 'settings',
                name: 'SettingsDesktop',
                component: () => import('@/views/profile/settings/Settings.vue'),
            },
            {
                path: 'language',
                name: 'LanguageDesktop',
                component: () => import('@/views/profile/settings/Language.vue'),
            },
            {
                path: 'notifications',
                name: 'NotificationsDesktop',
                component: () => import('@/views/profile/settings/Notifications.vue'),
            },
            {
                path: 'privacy',
                name: 'PrivacyDesktop',
                component: () => import('@/views/profile/settings/privacy/Privacy.vue'),
            },
            {
                path: 'contact',
                name: 'ContactsDesktop',
                component: () => import('@/views/profile/settings/Contacts.vue'),
            },
            {
                path: 'deletedata',
                name: 'DeleteDataDesktop',
                component: () => import('@/views/profile/settings/privacy/DeleteData.vue'),
            },
            {
                path: 'accountinactivation',
                name: 'AccountInactivationDesktop',
                component: () => import('@/views/profile/settings/privacy/AccountInactivation.vue'),
            },
            {
                path: 'dataportability',
                name: 'DataPortabilityDesktop',
                component: () => import('@/views/profile/settings/privacy/DataPortability.vue'),
            },
            {
                path: 'removeconsent',
                name: 'RemoveConsentDesktop',
                component: () => import('@/views/profile/settings/privacy/RemoveConsent.vue'),
            },
            { path: 'transport', name: 'TransportResearch', component: () => import('@/components/transport/TransportResearch.vue') },
            { path: 'park', name: 'ParkingResearch', component: () => import('@/components/parking/ParkingResearch.vue') },
            { path: 'parkdetails/:id', name: 'ParkDetails', component: () => import('@/components/parking/ParkDetails.vue') },
            { path: 'route', name: 'RouteResearch', component: () => import('@/components/routes/RouteResearch.vue') },
            { path: 'nearme', name: 'NearMe', component: () => import('@/components/nearme/NearMe.vue') },
            { path: 'favorites', name: 'ResearchFavorites', component: () => import('@/components/search/Favorite.vue') },
            { path: 'recent', name: 'ResearchRecents', component: () => import('@/components/search/RecentNav.vue') },
            { path: 'results', name: 'ResearchResults', component: () => import('@/components/search/ResearchResults.vue') },
            { path: 'transportresults', name: 'TransportResults', component: () => import('@/components/transport/TransportResults.vue') },
            { path: 'transport-filters', name: 'TransportFilters', component: () => import('@/components/transport/TransportFilters.vue') },
            { path: 'filters', name: 'Filters', component: () => import('@/components/parking/FiltersNav.vue') },
            { path: 'automatic', name: 'AutomaticRoutes', component: () => import('@/components/routes/AutoRouteResult.vue') },
            { path: 'direction', name: 'PathDirection', component: () => import('@/components/directions/PathDirection.vue') },
            { path: 'arrivedpark/:id', name: 'ArrivedPark', component: () => import('@/components/parking/ArrivedPark.vue') },
            { path: 'routedirection', name: 'RouteDirection', component: () => import('@/components/directions/RouteDirection.vue') },
            { path: 'routedetails', name: 'RouteDetails', component: () => import('@/components/routes/RouteDetails.vue') },
            { path: 'completedroute', name: 'CompletedRoute', component: () => import('@/components/routes/CompletedRoute.vue') },
            { path: 'parkingspot/:id', name: 'ParkingSpot', component: () => import('@/components/parking/ParkingSpot.vue') },
            { path: 'mapsettings', name: 'MapSettings', component: () => import('@/components/map/MapSettings.vue') },
            { path: 'busstop/:id', name: 'BusStop', component: () => import('@/components/stops/BusStop.vue') },
            { path: 'allownotifications', name: 'AllowNotifications', component: () => import('@/components/notifications/AllowNotification.vue') },
            { path: 'allowmicro', name: 'AllowMicrophone', component: () => import('@/components/notifications/AllowMicrophone.vue') },
            { path: 'notregistered', name: 'NotRegistered', component: () => import('@/components/notifications/NotRegistered.vue') },
            { path: 'error', name: 'ErrorNotification', component: () => import('@/components/notifications/ErrorNotification.vue') },
            { path: 'continueroute', name: 'ContinueRoute', component: () => import('@/components/notifications/ContinueRoute.vue') },
            { path: 'noconnection', name: 'NoConnection', component: () => import('@/components/notifications/NoConnection.vue') },
            { path: 'noGPS', name: 'NoGPS', component: () => import('@/components/notifications/NoGPS.vue') },
            { path: 'logoutconfirm', name: 'LogoutConfirm', component: () => import('@/components/notifications/LogoutConfirm.vue') },
            { path: 'registeredemail', name: 'RegisteredEmail', component: () => import('@/components/notifications/RegisteredEmail.vue') },
            { path: 'connection', name: 'Connection', component: () => import('@/components/notifications/Connection.vue') },
            { path: 'calcutateroute', name: 'CalcutateRoute', component: () => import('@/components/notifications/CalcutateRoute.vue') },
            { path: 'allowlocation', name: 'AllowLocation', component: () => import('@/components/notifications/AllowLocation.vue') },
            { path: 'notificationslist', name: 'NotificationsList', component: () => import('@/components/notifications/NotificationsList.vue') },
            { path: 'transitroutes', name: 'TransitRoutes', component: () => import('@/components/routes/TransitRoutes.vue') },
            {
                path: '/transitroutedetails/:id',
                name: 'TransitDetailsDesktop',
                component: () => import('@/components/routes/TransitDetailsDesktop.vue'),
            },
            { path: 'track-bus', name: 'TrackInsideBus', component: () => import('@/components/real-time/TrackInsideBus.vue') },
            { path: 'search-stop', name: 'SearchBusStop', component: () => import('@/components/stops/SearchBusStop.vue') },
            { path: 'bus-details', name: 'BusDetails', component: () => import('@/components/transport/BusDetails.vue') },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name != 'PathDirection' && to.name != 'TransitRoutes') {
        store.commit('map/setDirection', false);
    }
    next();
});

router.beforeEach((to, from, next) => {
    if (from.name === 'Login' && to.name === 'BottomMenu') {
        store.commit('map/setLocation', null);
    }
    next();
});

router.beforeEach((to, from, next) => {
    if (from.name === 'ParkingResearch') {
        store.commit('map/setParks', true);
        store.commit('map/setFreeParks', false);
        store.commit('map/setPayParks', false);
    }
    next();
});

export default router;
