<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <figure class="logo-name is-hidden-touch">
                <img src="@/assets/img/logo/ioCity_Logo_name.png" />
            </figure>
            <div class="container">
                <div class="is-hidden-desktop">
                    <figure class="image logo mx-auto">
                        <img src="@/assets/img/logo/logo_inicial.png" />
                    </figure>

                    <h1 class="title is-4 has-text-primary has-text-weight-medium">
                        {{ $t('views.initial.home.welcome') }}
                    </h1>
                    <div class="columns is-mobile is-centered">
                        <div class="column is-9-mobile is-4-tablet is-3-desktop">
                            <b-button @click="login" class="has-text-weight-medium" size="is-size-5" expanded type="is-primary">
                                {{ $t('views.initial.home.login') }}
                            </b-button>
                        </div>
                    </div>
                    <div class="columns is-mobile is-centered">
                        <div class="column is-9-mobile is-4-tablet is-3-desktop">
                            <b-button @click="register" class="has-text-weight-medium" size="is-size-5" expanded type="is-primary">
                                {{ $t('views.initial.home.register') }}
                            </b-button>
                        </div>
                    </div>
                    <div class="columns is-mobile is-centered">
                        <div class="column is-12-mobile is-4-tablet is-3-desktop">
                            <a @click="loginAsGuest" class="has-text-grey is-size-6">{{ $t('views.initial.home.guess') }}</a>
                        </div>
                    </div>
                </div>

                <div class="columns is-hidden-touch">
                    <div class="column is-8">
                        <figure>
                            <img src="@/assets/img/logo/loginIOcity.png" />
                        </figure>
                    </div>
                    <div class="column is-4 is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-space-evenly">
                        <div class="has-text-left">
                            <h1 class="title is-4 has-text-secondary has-text-weight-semibold">{{ $t('views.initial.home.welcome') }} 👋🏻</h1>
                            <b-button @click="login" class="my-4 px-10 has-text-weight-medium" size="is-size-5" expanded type="is-primary">
                                {{ $t('views.initial.home.login') }}
                            </b-button>
                            <b-button @click="register" class="my-4 px-10 has-text-weight-medium" size="is-size-5" expanded type="is-primary">
                                {{ $t('views.initial.home.register') }}
                            </b-button>
                            <a @click="loginAsGuest" class="has-text-grey is-size-6">{{ $t('views.initial.home.guess') }}</a>
                        </div>

                        <figure>
                            <img src="@/assets/img/logo/apoios_iocity.png" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Home',
        methods: {
            login() {
                this.$store.commit('auth/setGuestStatus', false);
                this.$router.push({ name: 'Login' });
            },
            register() {
                this.$store.commit('auth/setGuestStatus', false);
                this.$router.push({ name: 'Register' });
            },
            loginAsGuest() {
                this.$store.commit('auth/setGuestStatus', true);
                this.$router.push({ name: 'BottomMenu' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .logo {
        max-width: 450px;
    }
    .logo-name {
        max-width: 20%;
        position: absolute;
        top: 3%;
        left: 0;
    }
</style>
