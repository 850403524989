// initial state
const state = {
    showParks: true,
    showFreeParksOnly: false,
    showPayParksOnly: false,
    showStops: true,
    showRoutes: true,
    showDirection: false,
    showFavorites: false,
    route: {
        origin: null,
        destination: null,
        travelMode: null,
        result: null,
        time: new Date(),
    },
    location: null,
    showUserLocation: false,
    centerMyLocation: false,
};

// getters
const getters = {
    getParks(state, getters) {
        return state.showParks;
    },
    getFreeParks(state, getters) {
        return state.showFreeParksOnly;
    },
    getPayParks(state, getters) {
        return state.showPayParksOnly;
    },
    getStops(state, getters) {
        return state.showStops;
    },
    getRoutes(state, getters) {
        return state.showRoutes;
    },
    getFavorites(state, getters) {
        return state.showFavorites;
    },
    getDirection(state, getters) {
        return state.showDirection;
    },
    getRouteOrigin(state, getters) {
        return state.route.origin;
    },
    getRouteDestination(state, getters) {
        return state.route.destination;
    },
    getResult(state, getters) {
        return state.route.result;
    },
    getTravelMode(state, getters) {
        return state.route.travelMode;
    },
    getTime(state, getters) {
        return state.route.time;
    },
    getLocation(state, getters) {
        return state.location;
    },

    getUserLocation(state, getters) {
        return state.showUserLocation;
    },
    getCenterMyLocation(state, getters) {
        return state.centerMyLocation;
    },
};
//mutations
const mutations = {
    setParks(state, value) {
        state.showParks = value;
    },
    setFreeParks(state, value) {
        state.showFreeParksOnly = value;
    },
    setPayParks(state, value) {
        state.showPayParksOnly = value;
    },
    setStops(state, value) {
        state.showStops = value;
    },
    setRoutes(state, value) {
        state.showRoutes = value;
    },
    setFavorites(state, value) {
        state.showFavorites = value;
    },
    setDirection(state, data) {
        state.showDirection = data;
    },
    setRouteOrigin(state, data) {
        state.route.origin = data;
    },
    setRouteDestination(state, data) {
        state.route.destination = data;
    },
    setResult(state, data) {
        state.route.result = data;
    },
    setTravelMode(state, data) {
        state.route.travelMode = data;
    },
    setTime(state, data) {
        state.route.time = data;
    },
    setLocation(state, data) {
        state.location = data;
    },
    setUserLocation(state, data) {
        state.showUserLocation = data;
    },
    setCenterMyLocation(state) {
        state.centerMyLocation = !state.centerMyLocation;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
