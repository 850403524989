<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
    import favorites from '@/mixins/favorites';

    export default {
        mixins: [favorites],
        created() {
            const htmlClassName = 'dark-mode';
            if (this.$store.getters['darkmode/getDarkMode'] === true) {
                document.documentElement.classList.add(htmlClassName);
            } else {
                document.documentElement.classList.remove(htmlClassName);
            }
        },
    };
</script>
