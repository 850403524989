//initial state

const state = {
	language: (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage,
};

//getters
const getters = {
	getLanguage(state) {
		return state.language;
	},
};

// mutations
const mutations = {
	setLanguage(state, data) {
		state.language = data;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};
