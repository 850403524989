import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import i18n from './i18n';

//Vee-Validate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';

i18n.locale = store.getters['language/getLanguage'];

localize(i18n.locale);
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});
extend('required', {
    message: (_, values) => i18n.t('validations.required', values),
});

extend('password-equal', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: (_, values) => i18n.t('validations.password-equal', values),
});

extend('not-hashed', {
    validate(value) {
        return value.split('$').length < 5;
    },
    message: (_, values) => i18n.t('validations.not-hashed', values),
});
extend('must-be-checked', {
    validate: (value) => {
        return value === true;
    },
    message: (_, values) => i18n.t('validations.must-be-checked', values),
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, far, fab);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Buefy, {
    defaultIconComponent: 'font-awesome-icon',
    defaultIconPack: 'fas',
});

// SENTRY
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
// if (process.env.VUE_APP_SENTRY)
//     Sentry.init({
//         Vue,
//         dsn: process.env.VUE_APP_SENTRY,
//         integrations: [
//             new BrowserTracing({
//                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                 tracingOrigins: ['localhost', 'app.iocity.iotech.pt', /^\//],
//             }),
//         ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0,
//         trackComponents: true,
//         logErrors: true,
//         attachProps: true,
//         attachStacktrace: true,
//     });

// FIREBASE
// import firebase from 'firebase/app';
// if (process.env.VUE_APP_FIREBASE_API_KEY) {
// 	firebase.initializeApp({
// 		apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
// 		authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
// 		projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
// 		storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
// 		messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
// 		appId: process.env.VUE_APP_FIREBASE_APP_ID,
// 	});
// }

import GmapVue from 'gmap-vue';
Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAPS_KEY,
        libraries: 'places',
        installComponents: true,
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
