// API
import { getFavorites } from '@/api/favorites';
import { Geolocation } from '@capacitor/geolocation';

export default {
    created() {
        this.getFavorites();
    },
    methods: {
        async getFavorites() {
            let position = await this.getPosition().catch(() => {
                return null;
            });
            const favorites = await getFavorites({
                ...(position ? { calculateDistance: true, latitude: position.coords.latitude, longitude: position.coords.longitude } : {}),

                populate: ['stop', 'parkingLot', 'transport', 'route', 'parkingLot.picture'],
            });
            this.$store.commit('favorites/setFavorites', favorites.data.data);
        },
        async getPosition() {
            return await Geolocation.getCurrentPosition();
        },
    },
};
