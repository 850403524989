const state = () => ({
    selectedMenu: 'NearMe',
    isShowOpen: false,
});

const getters = {
    getSelectedMenu: (state) => {
        return state.selectedMenu;
    },
    getIsShowOpen: (state) => {
        return state.isShowOpen;
    },
};

const mutations = {
    setSelectedMenu: (state, menu) => {
        state.selectedMenu = menu;
    },
    setIsShowOpen: (state, isShowOpen) => {
        state.isShowOpen = isShowOpen;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
