import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: process.env.VUE_APP_NAME,
});

import auth from './modules/auth';
import app from './modules/app';
import park from './modules/park';
import map from './modules/map';
import stops from './modules/stops';
import favorites from './modules/favorites';
import language from './modules/language';
import recents from './modules/recents';
import routes from './modules/routes';
import darkmode from './modules/darkmode';
import transportRoutes from './modules/transportRoutes';
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: { auth, app, park, map, stops, favorites, language, recents, routes, darkmode, transportRoutes },
    plugins: [vuexLocal.plugin],
});
