// initial state
const state = {
    favorites: [],
};

// getters
const getters = {
	getFavorites(state, getters) {
		return state.favorites;
	},
	getFavoriteQuery(state, getters) {
		return state.query;
	},
};

// mutations
const mutations = {
	addToFavorites(state, data) {
		state.favorites.push(data);
	},
	removeFromFavorites(state, data) {
		const favoriteId = data.id;
		const favoriteIndex = state.favorites.findIndex((favorite) => favorite.id === favoriteId);
		state.favorites.splice(favoriteIndex, 1);
	},
	setFavorites(state, data) {
		state.favorites = data;
	},
	resetFavorites(state) {
		state.favorites = [];
	},
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
