// initial state
const state = {
    selectedPark: {
        floor: null,
        spot: null,
        section: null,
    },
    selectedSpot: null,
    parks: [],
    query: {
        sort: [],
        filters: {
            price: {
                $lte: 100,
            },
            distance: {
                $lte: 100,
            },
            $or: [
                {
                    type: { $in: ['indoor', 'outdoor'] },
                },
                {
                    type: { $null: true },
                },
            ],
        },
    },
};

const inital = Object.assign({}, state);

// getters
const getters = {
    getSelectedPark(state, getters) {
        return state.selectedPark;
    },
    getParks(state, getters) {
        return state.parks;
    },
    getParkQuery(state, getters) {
        return state.query;
    },
    getDefaultQuery(state, getters) {
        return inital.query;
    },
    getSelectedSpot(state, getters) {
        return state.selectedSpot;
    },
};

// mutations
const mutations = {
    setSelectedPark(state, spot) {
        state.selectedPark = spot;
    },
    setSelectedParkFloor(state, floor) {
        state.selectedPark.floor = floor;
    },
    setSelectedParkSpot(state, spot) {
        state.selectedPark.spot = spot;
    },
    setSelectedParkSection(state, section) {
        state.selectedPark.section = section;
    },
    setParks(state, parks) {
        state.parks = parks;
    },
    setParkQuery(state, query) {
        state.query = query;
    },
    setSelectedSpot(state, spot) {
        state.selectedSpot = spot;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
