// initial state
const state = {
    recents: [],
};

// getters
const getters = {
    getRecents(state, getters) {
        return state.recents;
    },
};

// mutations
const mutations = {
    addRecent(state, data) {
        let newData = _.clone(data);
        const type = newData.type;
        const filtered = state.recents.filter((recent) => {
            if (!recent[type]) return true;
            return recent[type] != newData[type];
        });
        console.log(filtered);
        state.recents = [newData, ...filtered];
    },
    resetRecent(state) {
        state.recents = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
