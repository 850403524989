import { get, post, put, remove } from '@/utils/http';
import qs from 'qs';

export function getFavorites(query = {}) {
    query = qs.stringify(query);
    return get(`/favorites?${query}`);
}

export function addFavorites(data) {
    const query = qs.stringify({
        populate: ['stop', 'parkingLot', 'transport', 'route'],
    });
    return post(`/favorites?${query}`, { data });
}

export function removeFavorites(id) {
    return remove(`/favorites/${id}`);
}
