import _ from 'lodash';

// initial state
const state = {
    /*  routes: [], */
    schedules: [],
    search: {
        from: null,
        to: null,
        time: null,
        timeType: null,
    },
    searchFromAut: null,
    query: {
        sort: ['stop_times.departure_time:asc'],
        filters: {
            route: {
                agency: {
                    id: {
                        $in: [],
                    },
                },
            },
            transport: {
                type: {
                    $in: [],
                },
            },
        },
    },
    searchQuery: {
        from: null,
        to: null,
        departure: null,
    },
};

// getters
const getters = {
    getSchedules(state, getters) {
        return state.schedules;
    },
    getScheduleQuery(state, getters) {
        return _.cloneDeep(state.query);
    },
    getSearch(state, getters) {
        return state.search;
    },
    getSearchFromAut(state, getters) {
        return state.searchFromAut;
    },
    getSearchQuery(state, getters) {
        return state.searchQuery;
    },
};

// mutations
const mutations = {
    setSchedules(state, data) {
        state.schedules = data;
    },
    setScheduleQuery(state, data) {
        state.query = data;
    },
    resetScheduleQuery(state) {
        state.query = {
            sort: ['departure_time:asc'],
            filters: {
                route: {
                    agency: {
                        id: {
                            $in: [],
                        },
                    },
                },
            },
        };
    },
    setSearch(state, data) {
        state.search = data;
    },
    resetSearch(state) {
        state.search = {
            from: null,
            to: null,
            time: null,
            timeType: null,
        };
    },
    setSearchQuery(state, data) {
        state.searchQuery = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
