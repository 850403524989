// initial state
const state = {
    stops: [],
};

// getters
const getters = {
    getStops(state, getters) {
        return state.stops;
    },
};

// mutations
const mutations = {
    setStops(state, stops) {
        state.stops = stops;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
