//initial state

const state = {
    isDarkModeActive: false,
};

//getters
const getters = {
    getDarkMode(state) {
        return state.isDarkModeActive;
    },
};

// mutations
const mutations = {
    setDarkMode(state) {
        const htmlClassName = 'dark-mode';
        state.isDarkModeActive = !state.isDarkModeActive;
        if (state.isDarkModeActive) {
            document.documentElement.classList.add(htmlClassName);
        } else {
            document.documentElement.classList.remove(htmlClassName);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
